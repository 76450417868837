.slider .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.swiper-slide {
    overflow: hidden;
}


.slider .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 30px;
}

.slider .swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 30px;
}

.slider .swiper-button-next:after,
.slider .swiper-button-prev:after {
    background-color: var(--bg-blinking-color-2);
    font-size: 24px;
    padding: 15px 20px;
    color: var(--text-color-3);
    transition: all 0.3s;
    border-radius: 10px;
}

.slider .swiper-button-next:hover:after,
.slider .swiper-button-prev:hover:after {
    background-color: var(--bg-blinking-color-3);
    color: var(--text-color-1);
    transition: all 0.3s;
}

.swiper-content {
    z-index: 1;
    position: absolute;
    left: 50%;
    margin: 0 auto;
    text-align: left;
    width: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.swiper-content h2 {
    font-weight: 700;
    font-size: 72px;
    /* margin: 15px 0px;*/
}

.swiper-content .btn_two {
    top: 50px !important;

}

.swiper-content h2,
.swiper-content p {
    color: var(--text-color-1);
    line-height: 1.2;
}

.swiper-content p {
    font-size: 20px;
    line-height:32px;    
    margin: 12px 0px 0px 0px;
}

/* Responsive.css */


@media (max-width: 374.98px) {

    .swiper-content h2 {

        font-size: 20px;

    }

    .swiper-content p {
        display: none;
    }

}

@media (min-width: 375.98px) and (max-width: 575.98px) {

    .swiper-content h2 {

        font-size: 24px;

    }

    .swiper-content p {
        display: none;
    }



}


@media (min-width: 576px) and (max-width: 767.98px) {

    .swiper-content h2 {

        font-size: 34px;

    }

    .swiper-content p {
        font-size: 18px;
        margin-bottom: 28px;
    }



}


@media (min-width: 768px) and (max-width: 991.98px) {

    .swiper-content h2 {

        font-size: 34px;

    }

}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}