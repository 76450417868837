.footer {
    background-color: var(--bg-black-color-light);
    position: relative;
    display: block;
    padding-top: 70px;
    padding-bottom: 50px;
    /* background-image: url(../images/footer-bg.webp);
    opacity: 10;*/

}

.footer-about-1 {
    background: #010101;
    margin-top: -25px;
    padding-bottom: 50px;
    padding-top: 0;
    position: relative;
}

.footer-logo h4 {
    margin: 0px 0px 25px 0px;
}

.footer h4 {
    color: var(--text-color-5);
    margin: 0px 0px 25px 0px;
}

.footer ul li {
    line-height: 28px;
}


.footer-social h5 {
    color: var(--text-color-5);
}



.footer-bottom-area {
    position: relative;
    display: block;
    padding: 30px 0 28px;
    background-color: var(--bg-black-color);
    z-index: 1;
}

.footer-bottom-area p {
    margin: 0px;
    padding: 0px;
}

.footer span {
    margin: 0px 5px 0px 0px;
}

.footer-social ul {
    margin: 0px 0px;
}

.footer-social ul li {
    float: left;
    margin: 5px 5px 0px 0px;
}


/* footer address */


.footer-contact-info {
    position: relative;
    display: block;

    /*top: 110px;*/
}



li .single-footer-contact-info .text p {
    color: var(--text-color-2);
    margin: 0;
}

li .single-footer-contact-info .icon,
li .single-footer-contact-info .text {
    display: table-cell;
   /* vertical-align: middle;*/
}

li .single-footer-contact-info .icon {
    /*width: 60px;*/
}

li .single-footer-contact-info .inner {
    position: relative;
    display: block;
    padding: 5px 10px 5px;
}

li .single-footer-contact-info .icon span::before {
    color: var(--text-color-2);
    font-size: 30px;
    transition: all 500ms ease;
}

.single-footer-contact-info:hover {
    color: var(--text-color-5);
}


@media (max-width: 374.98px) {

    .footer-contact-info-area {
        background-color: var(--bg-black-color);
    }

    li .single-footer-contact-info {
        max-width: 100%;
    }
}

@media (min-width: 375.98px) and (max-width: 575.98px) {

    .footer-contact-info-area {
        background-color: var(--bg-black-color);
    }

    li .single-footer-contact-info {
        max-width: 100%;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {

    .footer-contact-info-area {
        background-color: var(--bg-black-color);
    }

    li .single-footer-contact-info {
        max-width: 100%;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {

    .footer-contact-info-area {
        background-color: var(--bg-black-color);
    }

    li .single-footer-contact-info {
        max-width: 50%;
    }

}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .footer-contact-info-area {
        background-color: var(--bg-black-color);
    }
}


@media (min-width: 1200px) {}