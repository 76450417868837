.inner-pages {
    /* padding: 100px 0px;*/
}

.inner-pages p {
    font-size: 16px;
    line-height: 42px;
}

.inner-pages strong {
    color: #07930b;
}

.inner-pages .fa-angle-double-right {
    margin-right: 8px;

}

.inner-pages .HomeClients {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

.inner-pages .HomeBlog {
    background-color: transparent;
    padding: 80px 0px;
    margin: 0px;
}

.inner-pages .HomeTestimonial {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

.inner-pages .HomeLatestNews {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

.inner-pages .HomeGallery {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

.inner-pages .HomeVideo {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

/** inner page details css **/

.inner-pages-details {
    padding: 50px 0px 100px 0px;
}

.inner-pages-details h1 {
    margin: 40px 0px 20px 0px;
    color: #d82f1d;
    line-height: 42px;
    font-size: 34px;
}

.inner-pages-details h2 {
    margin: 10px 0px;
    color: #1a3988;
    line-height: 42px;
    font-size: 24px;
    font-weight: bold;
}

.inner-pages-details h3 {
    margin: 10px 0px;
    color: #157f36;
    line-height: 42px;
    font-size: 22px;
    font-weight: bold;
}

.inner-pages-details a {
    color: #157f36;

}

.inner-pages-details h4 {
    margin: 10px 0px;
    color: #ea0f76;
    line-height: 42px;
    font-size: 20px;
    font-weight: bold;
}

.inner-pages-details h5 {
    margin: 5px 0px;
    color: #f76b20;
    line-height: 28px;
    font-weight: bold;
}

.inner-pages-details h6 {
    margin: 10px 0px;
    color: #1fad9e;
    line-height: 42px;
    font-weight: bold;
}

.inner-pages-details p {
    margin: 0px 0px 10px 0px;
    color: #464546;
    line-height: 32px;
    font-size: 16px;
}

.inner-pages-details .post-meta {
    display: block;
    padding: 15px 0;
    margin: 10px 0;
    border-bottom: 1px solid #ebebeb;
}

.inner-pages-details .post-meta .ttm-meta-line {
    font-size: 13px;
    line-height: 15px;
    display: inline-block;
    padding-right: 25px;
    position: relative;
}

.inner-pages-details .post-meta .ttm-meta-line i {
    padding-right: 7px;
    font-size: 20px;
    color: #2d4a8a;
    vertical-align: middle;
}

.inner-pages-details .post-meta .ttm-meta-line:not(:last-child):after {
    position: absolute;
    content: "";
    height: 100%;
    width: 1px;
    top: 0;
    right: 10px;
    display: block;
    background-color: rgba(0, 0, 0, 0.2);
}

.inner-pages-details .faq h2 {
    margin: 0px;
}

.inner-pages-details .faq {
    margin: 50px 0px;
}


.accordion-button {

    color: #fff;
    background-color: #e81d2b;
    font-size: 28px;
}

.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e81d2b;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
}

.inner-pages-details ul {
    margin: 15px;
}

.inner-pages-details ul li {
    list-style-type: square;
}

.inner-cpntact-details{ padding:80px 0px; background:#d32e28}
.inner-cpntact-details h2{ font-size:22px; color:#fff; margin:15px 0px; font-weight:bold;}
.inner-cpntact-details h4{ font-size:18px; color:#fff; margin:15px 0px;}
.inner-cpntact-details h3{ font-size:20px; color:#fff; margin:15px 0px;}
.inner-cpntact-details p{ font-size:16px; color:#fff; margin:15px 0px;}

.table table {
	caption-side: bottom;
	border-collapse: collapse;
	width: 100%;
}
.table {
    border: 1px #f1f1f1 solid;
    margin: 25px 0px;
   
}

.table tr td {
    border: 1px #f1f1f1 solid;
}

.table tr th {
    border: 1px #1f073b solid;
    background-color: #2d0d52;
    color: #fff;
    padding: 8px;
}

.table tr th a {
    color: #fff;
}

.table tr {
    border: 1px solid #D9E4E6;
}

.table tr:nth-child(odd) {
    background-color: #EAF3F3;
}

.table> :not(caption)>*>* {
    padding: 0px;
}