.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
    padding:25px;
    margin:45px 0px 0px 0px;
}
.inner-pages-details-apply{ padding:0px 0px 70px 0px}

.inner-pages-details-apply h1{
    font-size: 32px;
     margin:25px 0px;
     color: #1a3988;
}

.inner-pages-details-apply h2 {
    font-size: 28px;
     margin:25px 0px;
     color: #157f36;
}

.inner-pages-details-apply h3 {
    font-size: 24px;
     margin:25px 0px;
     color: #ea0f76;
}

.inner-pages-details-apply h4 {
    font-size: 22px;
     margin:25px 0px;
     color: #fff;
}

.inner-pages-details-apply h5 {
    font-size: 20px;
     margin:25px 0px;
     color: #1fad9e;
}

.ttm-quote-form h3 {
    color: #fff;
    font-size: 22px;
    margin-bottom: 10px;
}

.ttm-quote-form h4 {
    color: #fff;
    font-size: 22px;
    margin-bottom: 30px;
}

.contact__input {
    width: 100%;
    border: 1px solid transparent;
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    font-size: 15px;
    color: #6f6f6f;
    background-color: #F4F6F9 !important;
    border-radius: 3px;
    margin-bottom: 15px;
}

.contact__input.txt-area {
    height: 180px;
    padding: 20px;
    resize: none;
    line-height: 1;
    margin-bottom: 25px;
}